export const ppRegex = /^[\x36]{1}.ilp.\w{5,}.\w|\d{5,}$/;
export const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

/* /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|eco|co|me|)\b/
 */
export const walletRegex = "";
export const IlpRegex = '(?=^.{1,1023}$)^(g|private|example|peer|self|test[1-3]?|local)([.][a-zA-Z0-9_~-]+)+$'
export const isrcReg = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/;
export const tickerRegex = /[?!@#$%^&*<>(){}[\]|A-Za-z0-9]{3,15}$/;

