import React, { useEffect, useState } from "react";
import usePodcaster from "../../../../hooks/usePodcaster";
import ContentWrapper from "../../../ContentWrapper";
import { SelectButton, SelectButtonWrapper } from "../../../Select";
import { SinglesReleasesTable } from "../../../ContentTable/ArtistTables";
import { ModalLoadingScreen } from "../../../LoadingScreen";
import { EpisodesReleaseTable } from "../../../ContentTable/PodcasterTables";
import { FormText, FormWrapper } from "../../../CustomForm/CustomFormElements";
import { getObjectLength } from "../../../../utils";
import { Nav, NavContainer, NavLink } from "../../../NavLink/NavLinkElements";

const EpisodeContent = ({ podcasterId, isLoaded, setLoaded, filter }) => {
  const { getPodcasterTopEpisodesByDate, getPodcasterEpisodes } = usePodcaster({
    id: podcasterId,
  });
  const [currentTab,setTab] = useState('top')
  const [isDiscogLoaded, setDiscogLoaded] = useState(false)
  const [episodes, setEpisodes] = useState({});
  const [royalties, setRoyalties] = useState({});
  const [topEpisodes, setTopEpisodes] = useState({});

  useEffect(() => {
    getMusicStats();

    return () => {};
  }, [podcasterId, filter]);


  useEffect(() => { 
    if(isLoaded){
    setDiscogLoaded(false)
    getDiscography()
}
  return () => {
    
  }
}, [podcasterId,filter, isLoaded])
  

const getDiscography = async () => {
        
           
  return await getPodcasterEpisodes(podcasterId, "all").then(discog => {
     if(discog?.length > 0){
      setEpisodes(discog/* {...discog?.songs, 
          songs: discog?.songs?.songs?.map(song => {
          return {...song,
              plays: getSongPlays(song?.songId)
          }
      })} */)
  }  
  setDiscogLoaded(true)
  }).catch(err => {
      setDiscogLoaded(true)
  })
 
}

  const getMusicStats = async () => {
    setLoaded(false);
    return await Promise.all([
      getPodcasterTopEpisodesByDate(podcasterId, filter, "all"),
    ])
      .then((music) => {
        if (music[0]?.length > 0 && music[0] !== false) {
          setTopEpisodes(music[0]);
        }

        /*  if(music[2]?.length > 0 && music[2] !== false){
                setAlbums(music[2])
            } */
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        return setLoaded(true);
      });
  };

  return (
    <>
      <ContentWrapper>
        {isLoaded && (
          <>
          <NavContainer style={{marginBottom: "5%"}}>
                <Nav>
                    <NavLink active={currentTab === 'top'} onClick={() => setTab('top')}>
                        Top Played
                    </NavLink>
                    <NavLink active={currentTab === 'releases'} onClick={() => setTab('releases')}>
                       Releases
                    </NavLink>
                    <NavLink active={currentTab === 'royalties'} onClick={() => setTab('royalties')}>
                       Regium Tokens
                    </NavLink>
                </Nav>
            </NavContainer>

            {currentTab === 'top' && topEpisodes?.episodes?.length > 0 && (
              <EpisodesReleaseTable
                releases={topEpisodes?.episodes}
                heading={"Top Played Episodes"}
              />
            )}
            {currentTab === 'releases' && <>
            {isDiscogLoaded && episodes?.episodes?.length > 0 && (
              <EpisodesReleaseTable
                releases={episodes?.episodes}
                heading={"Episode Releases"}
              />
            )}
            {!isDiscogLoaded && <ModalLoadingScreen/>}
            
            </>}
            {((currentTab === 'royalties' && !royalties?.royalties) ||(currentTab === 'top' && !topEpisodes?.episodes) || (currentTab === 'releases' &&  isDiscogLoaded && !episodes?.episodes)) && (
              <FormWrapper>
                <FormText>No Episodes Found</FormText>
              </FormWrapper>
            )}
          </>
        )}

        {!isLoaded && <ModalLoadingScreen text={"Loading Stats..."} />}
      </ContentWrapper>
    </>
  );
};

export default EpisodeContent;
