import React, { useEffect, useState } from 'react'
import { useModal } from '../../Modal/ModalContext';
import useRecordLabel from '../../../hooks/useRecordLabel';
import {UnavailableFeature } from '../../MenuOptions';
import { LABEL_FORM_INPUT_TYPES, SONAR_MUSE_NFT_STORAGE_KEY, TRANSACTION_OPTIONS } from '../../../utils/Enum';
import { getDownloadUrl, storage, storageRef, uploadBytes } from '../../Authorization/firebase';
import { newlineFormatter, stringFormatter, validateAddress } from '../../../utils';
import Modal from '../../Modal';
import QRCodeForm from '../../QRCodeForm';
import ContentWrapper from '../../ContentWrapper';
import OptionModal from '../../OptionModal';
import { HoverOption } from '../../MenuOptions/MenuOptionsElements';
import AlbumArt from '../../AlbumArt';
import { DEFAULT_IMAGE, getUserProfile, hashEmail } from '../../GravatarAPI';
import { FormInput1, FormLabel, FormText, FormWrapper } from '../../CustomForm/CustomFormElements';
import { PushSpinner } from 'react-spinners-kit';
import ToggleButton from '../../ToggleButton';
import { Theme } from '../../WebsiteThemeElements';
import { ActionButton, ActionButtonsContainer, ErrorText } from '../../Profile/ProfileContent/ProfileContentElements';
import { FaInfo } from 'react-icons/fa';
import ToolTip from '../../ToolTip';
import CurrencySelector from '../../CurrencySelector';
import { Button1, CustomButton, CustomButton1 } from '../../ButtonElement';
import { RecordLabelContentHeading, RecordLabelContentHeadingWrapper, RecordLabelContentWrapper, RecordLabelSettingsWrapper } from './RecordLabelProfileContentElements';
import MicropaymentsFundsClaimer, { MicropaymentFundsDisable } from '../../MicropaymentsFundsClaimer';
import { MdRefresh } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Nav, NavContainer, NavLink } from '../../NavLink/NavLinkElements';
import GravatarSettings from '../../GravatarAPI/GravatarSettings';
import { getRecordLabelPendingMicropayments } from '../../../utils/dbUtils/recordLabelUtils';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';

const RecordLabelSettings = ({label, labelId, getProfile, labelSettings, gravatarProfile, about}) => {
  const ppRegex = /^[\x36]{1}.ilp.\w{5,}.\w|\d{5,}$/;
  const {themeColors}= useWebsiteTheme()
  const { toggleModal, modalContent, closeModal, openModal, noCloseModal } = useModal();
  const navigate  = useNavigate()
  const { getRecordLabelFunds } = useRecordLabel({});
  
  const storageToken = SONAR_MUSE_NFT_STORAGE_KEY;
  const [currency, setCurrency] = useState(
    label?.currency ? label.currency : "XRP"
  );
  const [paymentChannel, setPaymentChannel] = useState(
    label?.paymentChannel?.channel ? label?.paymentChannel : undefined
  );
  const [funds, setFunds] = useState(label?.funds ? label?.funds : undefined); //useEffect to get funds in real time
  const [labelName, setName] = useState(label?.name);
  const [fullName, setFullName] = useState(label?.fullName);
  const [email, setEmail] = useState(label?.email);
  const [hasGravatarProfile, setHasGravatar] = useState(false)
  const [labelProfile, setProfile] = useState({});
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [fundsCurrency, setFundsCurrency] = useState("XRP")
  const [curencyRate, setCurrencyRate] = useState(0)
  const [isRateLoaded, setRateLoaded] = useState(0)
  const [tipping, setTipping] = useState(labelSettings?.tipping ? labelSettings?.tipping : false)
  const [errors, setErrors] = useState({});
  const [subscriptionFee, setFee] = useState(
    label?.subscriptionFee ? label.subscriptionFee : 0
  );
  const [showGallery, setShowGallery] = useState(
    labelSettings?.showGallery && labelSettings?.showGallery
  );
  const [showTrips, setTrips] = useState(labelSettings?.showTrips);
  const [headerType, setHeaderType] = useState(
    gravatarProfile?.labelBackground
      ? labelSettings?.headerType
        ? labelSettings.headerType
        : gravatarProfile.labelBackground.url
        ? "background"
        : "color"
      : false
  );
  const [bioType, setBioType] = useState(
    labelSettings?.bioType
        ? labelSettings.bioType
        : "gravatar"
  );
  const [profilePictureType, setProfilePictureType] = useState(
    labelSettings?.profilePictureType
      ? labelSettings?.profilePictureType
      : "gravatar"
  );

  const [bio, setBio] = useState(
    labelSettings?.bioType
      ? labelSettings?.bioType === "custom"
        ? label?.bio
        : gravatarProfile?.description
      : label?.bio
  );


  const [profilePicture, setProfilePicture] = useState(
    labelSettings?.profilePictureType
      ? labelSettings?.profilePictureType === "custom"
        ? label.profilePicture
        : {}
      : {}
  );
  const [paymentPointer, setPaymentPointer] = useState(label?.paymentPointer);
  const [walletAddress, setWalletAddress] = useState({ address: label?.uid });
  const [devnetAddress, setDevnetAddress] = useState(
    label?.devnetAddress ? label?.devnetAddress : ""
  );
  const [nftStorageApiKey, setNftStorageApiKey] = useState(
    label?.nftStorageAPIKey ? label?.nftStorageAPIKey : ""
  );
  const toggleStyle = {
    display: "flex",
    color: "white",
  };
  const [apiKeyType, setApiKeyType] = useState(
    label?.nftStorageAPIKey === storageToken ? true : false
  );
  const [currentTab, setTab] = useState('general')


  
  useEffect(() => {
    if(label?.email?.length > 0){
      getUserProfile(hashEmail(label?.email)).then((profile) => {
        if(profile){
          setHasGravatar(true)
          return
        }
        setHasGravatar(false)
        return
      }).catch(err => {
        setHasGravatar(false)
      })
    }
         
      return () => {}
    }, [label?.email]);


    const getFunds = async () => {
      getRecordLabelPendingMicropayments(labelId, setFunds)
      /* if(labelId && paymentChannel?.channel){
      const client = new Client(SERVERS.MAINNET[0]);
      let channels = [];
      await client.connect();
      try {
        let resp = await client.request({
          id: 1,
          command: "account_channels",
          account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
          destination_account: label?.uid,
          ledger_index: "validated",
        });
    
        // Create a function to run on each API call.
        function printLedgerResult() {
          //console.log(resp["result"]);
        }
        const ledger_data_index = resp["result"]["ledger_index"];
        // Execute function at least once before checking for markers.
        do {
          //printLedgerResult();
    
          if (resp["result"]["marker"] === undefined) {
            break;
          }
    
          // Specify the same ledger and add the marker to continue querying.
          const ledger_marker = await client.request({
            command: "account_channels",
            account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
            destination_account: label?.uid,
            ledger_index: ledger_data_index,
            marker: resp["result"]["marker"],
            ledger_index: "validated",
          });
          channels.push(...ledger_marker?.result?.channels);
          resp = ledger_marker;
        } while (true);
        client.disconnect();
        if (channels?.length > 0) {
          let c = channels.filter((c) => c?.channel_id === paymentChannel?.channel)[0];
          setFunds(c?.amount - c?.balance)
        } else {
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    } */
    };

  const enableMonetization = () => {
    openModal();
    modalContent(
      <>
        <OptionModal
          heading={"Enabling Web Monetization"}
          additionalText={
            "Note: To enable web monetization, you will need to sign a transaction with a fee of 0.1 XRP to fund your account"
          }
        >
          <HoverOption
            onClick={() =>
              noCloseModal(
                <QRCodeForm
                  heading={"Enabling Web Monetization"}
                  body={{
                    memo: `Record Company ${label?.uid} is signing transaction to enable Web Monetization`,
                    instruction: `Record Company ${label?.uid} is signing transaction to enable Web Monetization`,
                    sender: label?.uid,
                    labelId: labelId,
                    type: "record label"
                  }}
                  type={TRANSACTION_OPTIONS.ENABLE_WEB_MONETIZATION}
                  onSuccess={onMonetizationEnableSuccess}
                  onFail={onMonetizationEnableFail}
                  cancelQr={onMonetizationEnableCancel}
                />
              )
            }
          >
            Enable Web Monetization
          </HoverOption>
          <HoverOption>Cancel</HoverOption>
        </OptionModal>
      </>
    );
  };

  const onMonetizationEnableSuccess = (data) => {
    closeModal();
    modalContent();
    setPaymentChannel(data.paymentChannel);
    setFunds(data.funds);
  };


  const onMonetizationEnableFail = () => {
    closeModal();
    modalContent();
  };

  const onMonetizationEnableCancel = () => {
    closeModal();
    modalContent();
  };

  const claimFunds = () => {
    openModal();
    modalContent(
      <MicropaymentsFundsClaimer
        funds={funds}
        paymentChannel={paymentChannel}
        name={label?.uid}
        type={"Record Company"}
        id={labelId}
        onSuccess={() => {
          closeModal();
          modalContent();
        }}
      />
    );
  };

  const disableFunds = () => {
    openModal();
    modalContent(
      <MicropaymentFundsDisable
        funds={funds}
        paymentChannel={paymentChannel}
        name={label?.uid}
        id={labelId}
        type={"Label"}
        onSuccess={() => {
          closeModal();
          setPaymentChannel()
          modalContent();
        }}
      />
    );
  };


  const switchProfilePictureType = () => {
    if (profilePictureType === "gravatar") {
      setProfilePictureType("custom");
    } else {
      setProfilePictureType("gravatar");
    }
  };

  const switchBioType = () => {
    if (bioType === "gravatar") {
      setBioType("custom");
    } else {
      setBioType("gravatar");
    }
  };

  const switchHeaderType = () => {
    if (headerType === "color") {
      setHeaderType("background");
    } else {
      setHeaderType("color");
    }
  };

  const toggleTipping = () => {
    setTipping(!tipping)
  }

  const handleInput = (e) => {
    let cErrors = errors;
    if (e.target.value.trim() !== "") {
      switch (e.target.name) {
        case LABEL_FORM_INPUT_TYPES.NAME:
          delete cErrors?.name;
          setName(e.target.value);
          break;
        case LABEL_FORM_INPUT_TYPES.FULL_NAME:
          delete cErrors?.fullName;
          setFullName(e.target.value);
          break;
        case LABEL_FORM_INPUT_TYPES.EMAIL:
          delete cErrors?.email;
          setEmail(e.target.value);
          break;
        case LABEL_FORM_INPUT_TYPES.ADDRESS:
          delete cErrors?.address;
          setWalletAddress({ address: e.target.value });
          break;
        case LABEL_FORM_INPUT_TYPES.SUBSCRIPTION_FEE:
          setFee(parseInt(e.target.value));
          delete cErrors?.fee;
          break;
        case LABEL_FORM_INPUT_TYPES.BIO:
          setBio(newlineFormatter(e.target.value));
          break;
        case LABEL_FORM_INPUT_TYPES.PAYMENT_POINTER:
          setPaymentPointer(e.target.value);
          delete cErrors?.paymentPointer;
          break;
        case LABEL_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY:
          setNftStorageApiKey(e.target.value);
          delete cErrors?.nftStorageApiKey;
          break;
        case LABEL_FORM_INPUT_TYPES.PROFILE_PICTURE:
          let currArt = profilePicture;
          if (e.target.value && e.target.files[0]) {
            toggleModal();
            modalContent();
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e1) {
              //Initiate the JavaScript Image object.
              var image = new Image();

              //Set the Base64 string return from FileReader as source.
              image.src = e1.target.result;

              setProfilePicture({ file: e.target.files[0] });
              image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height < 1600 || width < 1600) {
                  alert("Height and Width must not be less than 1600px.");
                  setProfilePicture();
                  return false;
                }

                //let artBlob = convertFileToBytes(e.target.files[0])
                let imageFileName;
                if (!profilePicture?.name) {
                  imageFileName = `ProfilePicture.${
                    e.target.files[0].name.split(".")[
                      e.target.files[0].name.split(".").length - 1
                    ]
                  }`;
                  setProfilePicture((prev) => {
                    return { ...prev, name: imageFileName };
                  });
                } else {
                  imageFileName = profilePicture?.name;
                }
                const uploadTask = uploadBytes(
                  storageRef(storage, `labels/${labelId}/${imageFileName}`),
                  e.target.files[0],
                  { contentType: e.target.files[0].type }
                );

                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProfilePicture((prev) => {
                      return { ...prev, progress: progress };
                    });
                    /* if(isCancelSongUpload){
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                    } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                    }
                    } */
                    //console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case "paused":
                        break;
                      case "running":
                        /* if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                        break;
                      default:
                        /*  if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                        //console.log("Upload is " + progress + "% done");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        break;
                      case "storage/canceled":
                        setProfilePicture(currArt);
                        // User canceled the upload
                        break;
                      // ...
                      case "storage/unknown":
                        setProfilePicture(currArt);
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                      default:
                        break;
                    }
                  },
                  () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadUrl(uploadTask.snapshot.ref).then(
                      (downloadURL) => {
                        setProfilePicture((prev) => {
                
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                        //console.log("File available at", downloadURL);
                      }
                    );
                  }
                );
              };
            };
          }
          break;
        default:
          break;
      }
      setErrors(cErrors);
    }
  };

  const validatePaymentPointer = (pp) => {
    return ppRegex.test(pp);
  };

  const onSuccess = async () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
    setRequestUpdate(false);
    setUpdated(true);
    setErrors({ message: "Profile successfully updated" });
    navigate(`/label/${labelId}`)
  };

  const onFailure = () => {
    setRequestUpdate(false);
    setUpdated(false);
    setErrors({ message: "Profile failed to update" });
  };

  const cancelUpload = () => {
    setRequestUpdate(false);
    setUpdated(false);
  };

  const validateInfo = () => {
    let cErrors = {};
    if (
      (labelName.length > 0 && labelName.trim() === "") ||
      labelName === ""
    ) {
      cErrors.name = "Artist Name cannot be empty";
    }
    if (fullName?.length > 0 && fullName?.trim() === "") {
      setFullName(labelName);
    }
    if (
      walletAddress.address.length > 0 &&
      validateAddress(walletAddress.address)
    ) {
      if (!walletAddress?.confirmed && walletAddress.address !== label.uid) {
        cErrors.address = "You have not confirmed changing address";
      }
      if (walletAddress.address.trim() === "") {
        setWalletAddress({ address: label.uid });
      }
    }
    if (walletAddress.address.trim() === "") {
      setWalletAddress({ address: label.uid });
    }

    if (bioType === "custom" && (bio === about.bio || bio.trim() === "")) {
      setBio();
    }
    if (Object.keys(cErrors).length === 0) {
      return true;
    } else if (Object.keys(cErrors).length > 0) {
      setErrors((prev) => {
        return { ...prev, ...cErrors };
      });
      return false;
    }
  };

  const updateProfile = () => {
    if (validateInfo() === true) {
      setErrors({});
      setRequestUpdate(true);
      return;
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          message: "Unable to update label. Please review your information",
        };
      });
      return;
    }
  };

  return (
    <>
      {requestUpdate && (
        <Modal isOpen={requestUpdate}>
          <QRCodeForm
            heading={`Updating ${labelName}'s Profile`}
            body={{profile: {
              labelSettings: {
                bioType,
                headerType,
                showTrips,
                showGallery,
                profilePictureType,
                tipping: tipping,
              },
              bio: bioType === "custom" && bio,
              name: labelName,
              fullName: fullName,
              profilePicture: profilePicture?.url
                ? {
                    url: profilePicture?.url,
                    ref: profilePicture?.ref,
                  }
                : false,
              paymentPointer: paymentPointer?.trim()?.length > 0 ? paymentPointer?.trim() : undefined,
              devnetAddress: devnetAddress,
              uid: walletAddress.address,
              email: email,
              subscriptionFee: subscriptionFee > 0 ? subscriptionFee : undefined,
              currency: subscriptionFee > 0 ? currency : undefined,
              nftStorageAPIKey: nftStorageApiKey,
            }, instruction: `Updating profile for label ${labelName} (${labelId})`,
            labelId: labelId,
            sender: label.uid,}}
            cancelQr={cancelUpload}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.UPDATE_LABEL_PROFILE}
          />
        </Modal>
      )}
      <ContentWrapper heading={`${label?.name} Settings`}>
        <NavContainer style={{marginBottom: "5%"}}>
          <Nav>
            <NavLink active={currentTab === 'general'} onClick={() => setTab('general')}>
              General
            </NavLink>
            <NavLink active={currentTab === 'gravatar'} onClick={() => setTab('gravatar')}>
              Gravatar
            </NavLink>
            <NavLink active={currentTab === 'monetization'} onClick={() => setTab('monetization')}>
              Payments & Monetization
            </NavLink>
          </Nav>
        </NavContainer>
        {currentTab === 'general' && <FormWrapper>

<RecordLabelContentHeading>General</RecordLabelContentHeading>
<FormWrapper>
<FormLabel>Record Label Name</FormLabel>
<FormInput1
  name={LABEL_FORM_INPUT_TYPES.NAME}
  placeholder="Name"
  value={labelName}
  onChange={handleInput}
/>
{errors?.name && <ErrorText>{errors.name}</ErrorText>}
</FormWrapper>

<FormWrapper>
<FormLabel>Full Name</FormLabel>
<FormInput1
  name={LABEL_FORM_INPUT_TYPES.FULL_NAME}
  placeholder="Full Name"
  value={fullName}
  onChange={handleInput}
/>
</FormWrapper>
<FormWrapper>
<FormLabel>Management address</FormLabel>
<FormInput1
  name={LABEL_FORM_INPUT_TYPES.ADDRESS}
  disabled={true}
  placeholder="Management address"
  value={walletAddress.address}
  onChange={handleInput}
/>
</FormWrapper>
</FormWrapper>}
          {currentTab === 'gravatar' &&<FormWrapper>
            <RecordLabelContentHeading>Gravatar Settings</RecordLabelContentHeading>
            <FormWrapper>
              {profilePictureType === "custom" && (
            <>
              <label
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleModal();
                  modalContent(
                    <OptionModal heading={"Profile Picture"}>
                      <HoverOption>
                        <label for={LABEL_FORM_INPUT_TYPES.PROFILE_PICTURE}>
                          Upload New Image
                        </label>
                      </HoverOption>
                      {profilePicture?.url && (
                        <HoverOption
                          onClick={() => {
                            setProfilePicture();
                            toggleModal();
                            modalContent();
                          }}
                        >
                          Delete
                        </HoverOption>
                      )}
                    </OptionModal>
                  );
                }}
              >
                {
                  <AlbumArt
                    isRounded={true}
                    size={"18rem"}
                    alt={labelName}
                    albumArt={
                      profilePicture?.url
                        ? profilePicture?.url
                        : DEFAULT_IMAGE(600)
                    }
                  />
                }
                <FormInput1
                  style={{ display: "none" }}
                  id={LABEL_FORM_INPUT_TYPES.PROFILE_PICTURE}
                  name={LABEL_FORM_INPUT_TYPES.PROFILE_PICTURE}
                  onChange={handleInput}
                  type={"file"}
                  accept="image/x-png,image/jpeg, image/jpg"
                  multiple="false"
                />
              </label>
              {profilePicture?.progress > 0 && !profilePicture?.url && (
            <>
              <PushSpinner loading={profilePicture?.url ? false : true} />
              {profilePicture?.progress < 100
                ? `${profilePicture?.progress.toFixed(2)}%`
                : `Processing`}
            </>
          )}
            </>
          )}
          <GravatarSettings type={'Label'} hasGravatarProfile={hasGravatarProfile} email={email} options={{
            profilePictureType,
            switchProfilePictureType,
            headerType,
            switchHeaderType,
            bioType,
            bio,
            bioInputName: LABEL_FORM_INPUT_TYPES.BIO,
            switchBioType,
            handleInput,
            tipping,
            setTipping,
            showGallery,
            setShowGallery,
            showTrips,
            setTrips,
            about,
            toggleTipping
          }}/>
          </FormWrapper>
          </FormWrapper>}
       {currentTab === 'monetization' && <FormWrapper>

        <FormWrapper>
          <RecordLabelSettingsWrapper>
            <>
              <FormLabel>
                <>
                  Micropayments Subscription{" "}
                  <ToolTip
                    style={{ marginLeft: "2%" }}
                    text={
                      "Receive micropayments for music streams directly to your XRP wallet address."
                    }
                  >
                    <FaInfo
                      color={themeColors?.secondaryBg}
                      style={{
                        marginLeft: "1%",
                        border: "1px solid",
                        borderRadius: "50%",
                      }}
                    />
                  </ToolTip>
                </>
              </FormLabel>
              {!paymentChannel?.channel && (
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    enableMonetization();
                  }}
                >
                  Enable
                </ActionButton>
              )}
              {paymentChannel?.channel && (
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => disableFunds()}
                >
                  Disable
                </ActionButton>
              )}
            </>
          </RecordLabelSettingsWrapper>
          {paymentChannel?.channel && funds / 1000000 > 0 && (
            <><RecordLabelSettingsWrapper style={{justifyContent: 'space-between'}}>
              <CurrencySelector setCurrency={setFundsCurrency} currency={fundsCurrency}/>
              <FormText>
                Available Funds:{" "}
                <div style={{ marginLeft: "2%" }}>
                  {fundsCurrency !== "XRP" && !isRateLoaded && 'Loading Balance...'}
                  {fundsCurrency !== "XRP" && isRateLoaded && parseFloat((funds / 1000000) * curencyRate).toFixed(6)}
                  {fundsCurrency === "XRP" && parseFloat(funds / 1000000).toFixed(6)} {fundsCurrency}
                </div>
              </FormText>

              
            </RecordLabelSettingsWrapper>
            <RecordLabelSettingsWrapper>
            {(funds / 1000000) < 0.1 && <CustomButton onClick={() => {getFunds()}}><small>Refresh Funds <MdRefresh color="white" /></small></CustomButton>}
            <ActionButtonsContainer>
            {(funds / 1000000) >= 0.1 && (
              <><CustomButton onClick={() => {getFunds()}}>Refresh Funds <MdRefresh color="white" /></CustomButton>
                <CustomButton1
                  onClick={() => {
                    claimFunds();
                  }}
                >
                  Claim Funds
                </CustomButton1></>
              )}</ActionButtonsContainer>
            </RecordLabelSettingsWrapper></>
          )}
          </FormWrapper>
         
{label?.isVerified && (
  <FormWrapper>
    <FormLabel>Monthly Subscription Fee <CurrencySelector setCurrency={setCurrency} currency={currency} /></FormLabel>
    
    <FormInput1
      name={LABEL_FORM_INPUT_TYPES.SUBSCRIPTION_FEE}
      type={"number"}
      min={0}
      placeholder="Subscription Fee"
      value={subscriptionFee}
      onChange={handleInput}
    />
  </FormWrapper>
)}
<FormWrapper>
  <FormLabel>Payment Pointer (ILP)</FormLabel>
  <FormInput1
    name={LABEL_FORM_INPUT_TYPES.PAYMENT_POINTERS}
    placeholder="Payment Pointer"
    value={paymentPointer}
    onChange={handleInput}
  />
</FormWrapper>

{/* <RecordLabelSettingsWrapper>
  <FormLabel>
    Devnet Wallet <XRPL setAddress={setDevnetAddress} />{" "}
  </FormLabel>
  <FormInput1
    name={"Devnet Wallet"}
    placeholder="NFT Dev Net Wallet Address"
    value={devnetAddress}
    onChange={handleInput}
  />
</RecordLabelSettingsWrapper>
<FormText>
  A Dev Net wallet address to test out creating and selling NFToken
  collections on our platform.
</FormText>
<FormText>
  This address will be used to sign NFToken transactions on the XRPL
  Dev Net <b> ONLY</b>.
</FormText>
<RecordLabelSettingsWrapper>
  <FormLabel>
    NFT.Storage API Key{" "}
    <FormButton1
      onClick={() => {
        if(storageToken !== nftStorageApiKey){
          setNftStorageApiKey(storageToken);
          return
        }
        setNftStorageApiKey()
        
      }}
    >
      {nftStorageApiKey === storageToken ? 'Use Custom Key' :'Sonar Muse Key' }
    </FormButton1>
  </FormLabel>

  <FormInput1
    name={LABEL_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY}
    placeholder="NFT.Storage API Key"
    value={nftStorageApiKey}
    readOnly={nftStorageApiKey === storageToken}
    type={nftStorageApiKey === storageToken ? 'password' : 'text'}
    onChange={handleInput}
  />
</RecordLabelSettingsWrapper>
<FormText>
  It's safe to provide us with your NFT.Storage API key! This will
  ensure that you have control of the data you upload onto IPFS via
  Sonar Muse. We will also provide labels with the option to use the
  Sonar Muse API key to store their metadata.
</FormText>
<FormText>
  Note: You an always revoke and change your API key via NFT.Storage
</FormText> */}

</FormWrapper>}
       
        
        <RecordLabelContentWrapper>
          {errors?.message && <ErrorText>{errors.message}</ErrorText>}
          <ActionButtonsContainer>
            <Button1 style={{marginRight: "2%"}} onClick={() => {
               navigate(-1);
            }}>Cancel</Button1>
            <Button1 style={{marginRight: "2%"}} onClick={updateProfile}>Update Profile</Button1>
          </ActionButtonsContainer>
        </RecordLabelContentWrapper>
      </ContentWrapper>
    </>
  );
}
export default RecordLabelSettings