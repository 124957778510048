import React, { useEffect, useState } from "react";
import {
  DashboardContainer,
  DashboardHeader,
  GlassContainer,
} from "./DashboardElements";
import RevenueCard from "../Card/RevenueCard";
import {

  FaChevronRight,


} from "react-icons/fa";
import { RevenueCards } from "../Card/RevenueCard/RevenueCardElements";
import MainDash from "./MainDash";
import RightSide from "./RightSideDashboard";
import Chip from "../Chip";
import { useAuth } from "../../contexts/AuthContext";
import { getObjectLength } from "../../utils";
import { useNavigate } from "react-router-dom";
import { CONTENT_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import useArtist from "../../hooks/useArtist";
import { useModal } from "../Modal/ModalContext";
import ArtistSettings from "../ArtistProfile/ArtistContent/ArtistSettings";
import OptionModal from "../OptionModal";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import {
  getArtistAlbums,
  getArtistFollowers,
  getArtistNotifications,
  getArtistPendingMicropayments,
  getArtistSongs,
  getTopPlayedSongsByArtist,
} from "../../utils/dbUtils/artistUtils";
import {
  getPodcasterEpisodes,
  getPodcasterFollowers,
  getPodcasterNotifications,
  getPodcasterPendingMicropayments,
  getTopPlayedPodcasterEpisodes,
} from "../../utils/dbUtils/podcasterUtils";
import PodcasterSettings from "../PodcasterProfile/PodcasterProfileContent/PodcasterSettings";
import RecordLabelSettings from "../RecordLabelProfile/RecordLabelProfileContent/RecordLabelSettings";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import usePodcaster from "../../hooks/usePodcaster";
import useRecordLabel from "../../hooks/useRecordLabel";
import {
  getRecordLabelAlbums,
  getRecordLabelArtists,
  getRecordLabelFollowers,
  getRecordLabelNotifications,
  getRecordLabelPendingMicropayments,
  getRecordLabelSongs,
} from "../../utils/dbUtils/recordLabelUtils";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import ArtistList from "../ContentList/ArtistList";
import LabelArtistAdder from "../LabelArtistAdder";
import {ArtistDashboard as ArtistDash} from "./MainDash/ArtistDashboard";
import PodcasterDash from "./MainDash/PodcasterDashboard";
import LabelDash from "./MainDash/LabelDashboard";
import { Header } from "../Header";
import { updateWebPageIcon } from "../../utils/MusicUtils";
import QRCodeForm from "../QRCodeForm";
import MicropaymentsFundsClaimer, { MicropaymentFundsDisable } from "../MicropaymentsFundsClaimer";
import axiosInstance from "../Authorization/client";
import { SettingsWrapper } from "../ArtistProfile/ArtistContent/ArtistContentElements";
import { CustomButton, CustomButton1 } from "../ButtonElement";
import { ActionButton, ActionButtonsContainer } from "../ArtistProfile/ArtistHeader/ArtistHeaderElements";
import ToolTip from "../ToolTip";
import { FormH1, FormLabel, FormText, FormWrapper } from "../CustomForm/CustomFormElements";
import CurrencySelector from "../CurrencySelector";
import { MdRefresh } from "react-icons/md";
import ContentWrapper from "../ContentWrapper";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
//import { RiXrpLine } from "react-icons/ri";

const Dashboard = () => {
  const { currentUser, profiles, logout, setCurrentProfile,isArtist, isPodcaster, isLabel  } = useAuth();
  const [isLoaded, setLoaded] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    updateWebPageIcon('https://artists.sonarmuse.org/favicon.ico')
    return () => {}
  },[])

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if(currentUser?.uid && getObjectLength(profiles) > 0){
    let currentProfile = localStorage.getItem('currentProfile') ? localStorage.getItem('currentProfile') : undefined
    setLoaded(true)
   switch (currentProfile) {
    case 'artist':
      setCurrentProfile('artist')
      navigate(`/home/artist/${profiles?.artist?.artistId}`, { replace: true });
      break;
    case 'podcaster':
      setCurrentProfile('podcaster')
      navigate(`/home/podcaster/${profiles?.podcaster?.podcasterId}`, {
        replace: true,
      })
      break;
    case 'label':
      setCurrentProfile('label')
      navigate(`/home/label/${profiles?.label?.labelId}`, { replace: true });
      break;
   
    default:
      
     let keys = Object.keys(profiles)
     if(keys?.length > 0){
     localStorage.setItem('currentProfile', keys[0])
     setCurrentProfile(keys[0])
     navigate(`/home/${keys[0]}/${profiles[keys[0]][keys[0]+'Id']}`, { replace: true });
    }else{
      
      logout()
    }

      break;
   
  }}
    return () => {};
  }, [currentUser]);

  return (
    <>
      <DashboardContainer>
        <Header title={'Dashboard'}/>
        <GlassContainer>
          {isLoaded && <MainDash />}
          {!isLoaded && <ModalLoadingScreen text={'Loading Please Wait'}/>}
        </GlassContainer>
      </DashboardContainer>
    </>
  );
};
export const ArtistDashboard = ({ id, artist }) => {
  const { currentUser, profiles, isArtist } = useAuth();
  const [currentProfile, setCurrenProfile] = useState(artist);
  const {
    getArtistProfile,
  } = useArtist({ id: id });
  const navigate = useNavigate();

  const { openModal, closeModal, modalContent, noCloseModal } = useModal();
  const [artistProfile, setArtistProfile] = useState();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search)
 

  const getProfile = async () => {
    return await getArtistProfile(id)
      .then((data) => {
        if (data !== false || data.error) {
          setArtistProfile(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <DashboardContainer>
      <DashboardHeader>
          <Header title={`${artist?.artistName}'s Dashboard (Artist)`}/>
        </DashboardHeader>
        <GlassContainer>
        <MicropaymentsViewer type={'artist'} getProfile={getArtistProfile} id={id} />
          <ArtistDash artist={{...artist, ...artistProfile}} getProfile={getProfile}/>
        </GlassContainer>
      </DashboardContainer>
    </>
  );
};

export const LabelDashboard = ({ id, label }) => {
  const { currentUser, profiles } = useAuth();
  const { getRecordLabelProfile } = useRecordLabel({ id: id });
  const [currentProfile, setCurrenProfile] = useState(label);
  const [recordLabelProfile, setProfile] = useState({});
  const { openModal, closeModal, modalContent } = useModal();
  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const [songs, setSongs] = useState([]);
  const [artists, setArtists] = useState([]);
  const [followers, setFollowers] = useState();
  const [notifications, setNotifications] = useState();
  const [isLoaded, setLoaded] = useState(false);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search)

  const getProfile = async () => {
    return await getRecordLabelProfile(id).then((data) => {
        if (data !== false || data.error) {
          setProfile(data)
        }
      })
      .catch((err) => {
        navigate(-1);
      });
  };

  return (
    <>
      <DashboardContainer>
       {!params?.has("settings", "true") && <DashboardHeader>
        <Header title={<>{currentProfile?.labelName}'s Dashboard (Label)</>}/>
        </DashboardHeader>}
        <MicropaymentsViewer id={id} getProfile={getRecordLabelProfile} type={'label'}/>
        <GlassContainer>
         <LabelDash label={{...label, ...recordLabelProfile}} getProfile={getProfile}/>
        </GlassContainer>
      </DashboardContainer>
    </>
  );
};

export const PodcasterDashboard = ({ id, podcaster }) => {
  const { currentUser, profiles } = useAuth();
  const { getPodcasterProfile } = usePodcaster({ id: id });

  const [currentProfile, setCurrenProfile] = useState(podcaster);
  const navigate = useNavigate();

  const [isLoaded, setLoaded] = useState(false);
  const { openModal, closeModal, modalContent } = useModal();
  const [podcasterProfile, setProfile] = useState();
  const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)


  const getProfile = async () => {
    return await getPodcasterProfile(id)
      .then((profile) => {
        if (profile) {
          setProfile({ podcasterId: id, ...profile });
          if (profile?.profile?.paymentChannel) {
          } else {
          }
        }
        setLoaded(true);
        return;
      })
      .catch((err) => {
        navigate(-1);
      });
  };

  return (
    <>
      <DashboardContainer>
       <DashboardHeader>
          <Header title={`${podcaster?.podcasterName}'s Dashboard (Podcaster)`}/>
        </DashboardHeader>
        <GlassContainer>
        <MicropaymentsViewer id={id} getProfile={getPodcasterProfile} type={'podcaster'}/>

          <PodcasterDash podcaster={podcaster} getProfile={getProfile}/>
        </GlassContainer>
      </DashboardContainer>
    </>
  );
};

export const ProfilePicker = ({ currentProfile }) => {
  const { currentUser, profiles, setCurrentProfile } = useAuth();
  const navigate = useNavigate();
  const { closeModal, modalContent } = useModal();
  return (
    <>
      <OptionModal heading={"Change Profile"}>
        {currentUser?.profiles?.artist?.artistId &&
          currentProfile?.artistId !==
            currentUser?.profiles?.artist?.artistId && (
            <HoverOption
              onClick={() => {
                setCurrentProfile('artist')
                localStorage.setItem('currentProfile', 'artist')
                navigate(
                  `/home/artist/${currentUser?.profiles?.artist?.artistId}`
                );
                closeModal();
              }}
            >
              {currentUser?.profiles?.artist?.artistName} (Artist)
            </HoverOption>
          )}
        {currentUser?.profiles?.podcaster?.podcasterId &&
          currentProfile?.podcasterId !==
            currentUser?.profiles?.podcaster?.podcasterId && (
            <HoverOption
              onClick={() => {
                setCurrentProfile('podcaster')
                localStorage.setItem('currentProfile', 'podcaster')

                navigate(
                  `/home/podcaster/${currentUser?.profiles?.podcaster?.podcasterId}`
                );
                closeModal();
              }}
            >
              {currentUser?.profiles?.podcaster?.podcasterName} (Podcaster)
            </HoverOption>
          )}
        {currentUser?.profiles?.label?.labelId &&
          currentProfile?.labelId !== currentUser?.profiles?.label?.labelId && (
            <HoverOption
              onClick={() => {
                setCurrentProfile('label')
                localStorage.setItem('currentProfile', 'label')

                navigate(
                  `/home/label/${currentUser?.profiles?.label?.labelId}`
                );
                closeModal();
              }}
            >
              {currentUser?.profiles?.label?.labelName} (Label)
            </HoverOption>
          )}
      </OptionModal>
    </>
  );
};

export function ArtistMenu({ type, currentProfile, getProfile }) {
  const { openModal, closeModal, modalContent } = useModal();
  const { profiles, currentUser } = useAuth();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search)

  return (
    <>
      <OptionModal heading={`${type === 'artist' ? currentProfile?.artistName : type === 'podcaster' ? currentProfile?.podcasterName : currentProfile?.labelName }`}>
        {getObjectLength(profiles) > 1 && (
          <HoverOption
            onClick={() => {
              openModal();
              modalContent(<ProfilePicker currentProfile={currentProfile} />);
            }}
          >
            Switch Profile <FaChevronRight />
          </HoverOption>
        )}
        <HoverOption
          onClick={() => {
            switch (type) {
              case CONTENT_TYPES.ARTIST:
                navigate(`/artist/${currentProfile?.artistId}`);
                break;
              case CONTENT_TYPES.PODCASTER:
                navigate(`/podcaster/${currentProfile?.podcasterId}`);

                break;
              case CONTENT_TYPES.RECORD_LABEL:
                openModal(
                  <OptionModal>
                    <HoverOption
                      onClick={() => {
                        navigate(`/label/${currentProfile?.labelId}`);
                      }}
                    >
                      View Label Profile
                    </HoverOption>
                    <HoverOption
                      onClick={() => {
                        //show artist roster
                      }}
                    >
                      Artist Roster
                    </HoverOption>
                  </OptionModal>
                );
                break;

              default:
                break;
            }
            closeModal();
          }}
        >
          View Profile
        </HoverOption>
        {type === CONTENT_TYPES.RECORD_LABEL && (
          <>
            <HoverOption
              onClick={() => {
                openModal();
                modalContent(<LabelRosterViewer label={currentProfile} />);
              }}
            >
              Artist Roster
            </HoverOption>
          </>
        )}
        <HoverOption
          onClick={() => {
            //params.set('settings', 'true')
            console.log('settings')
                navigate(`/home/${type}/${currentProfile[`${type}Id`]}/settings`);
                closeModal()
          }}
        >
          Profile Settings
        </HoverOption>
      </OptionModal>
    </>
  );
}

function LabelRosterViewer({ label }) {
  const { openModal, closeModal, modalContent } = useModal();
  const [isLoaded, setLoaded] = useState(false);
  const [artists, setArtists] = useState([]);
  useEffect(() => {
    getArtists();

    return () => {};
  }, [label]);

  async function getArtists() {
    await getRecordLabelArtists(label.labelId, setArtists);
    setLoaded(true);
  }

  return (
    <OptionModal>
      {!isLoaded && <LoadingScreen transparent={true} />}
      {isLoaded && (
        <>
          <HoverOption
            onClick={() => {
              //Redirect to page instead
              openModal();
              modalContent(
                <>
                  <LabelArtistAdder />
                </>
              );
            }}
          >
            Add New Artist To Roster
          </HoverOption>
          {artists?.length > 0 && (
            <>
              <ArtistList
                content={artists}
                onButtonClick={(i) => {
                  openModal();
                  modalContent(<LabelArtistViewer artist={artists[i]} />);
                }}
              />
            </>
          )}
        </>
      )}
    </OptionModal>
  );
}

function LabelArtistViewer({ artist }) {
  return (
    <OptionModal heading={artist?.name}>
      <></>
    </OptionModal>
  );
}

function MicropaymentsViewer({type, id, getProfile}) {
  const { openModal, closeModal, modalContent, noCloseModal } = useModal();
  const {themeColors}= useWebsiteTheme()
  const [isLoaded, setLoaded] = useState(false)
  const [funds, setFunds] = useState(0)
  const [fundsCurrency, setFundsCurrency] = useState("XRP")
  const [curencyRate, setCurrencyRate] = useState(0)
  const [isRateLoaded, setRateLoaded] = useState(0)
  const [profile, setProfile] = useState({})
  const [paymentChannel, setPaymentChannel] = useState({})

  useEffect(() => {
    if( fundsCurrency !== "XRP" ){
      if(fundsCurrency === "WYM"){
        setCurrencyRate("XRP")
        return
      }
      setRateLoaded(false)
      axiosInstance.get(`/api/${fundsCurrency}/getPrice`).then((rate) => {
        if(rate?.data?.price){
          setCurrencyRate(rate.data?.price)
          setRateLoaded(true)
          return
        }
        setCurrencyRate("XRP")
        setRateLoaded(true)
      }).catch(err => {
        setCurrencyRate("XRP")
        setRateLoaded(true)
      })
    }
  }, [fundsCurrency])


  useEffect(() => {
    setLoaded(false)
    getProfile(id).then(p => {
      setProfile(p)
      if(p?.profile?.paymentChannel){
        setPaymentChannel(p?.profile?.paymentChannel)
        getFunds()
      }
      setLoaded(true)
    })
    
    return () => {};
  }, [id]);

  const getFunds = async () => {
    switch (type) {
      case 'artist':
        getArtistPendingMicropayments(id, setFunds)
        break;
      case 'podcaster':
        getPodcasterPendingMicropayments(id, setFunds)
        break;
      case 'label':
        getRecordLabelPendingMicropayments(id, setFunds)
        break;
    
      default:
        break;
    }
    
    /* if(artistId && paymentChannel?.channel){
    const client = new Client(SERVERS.MAINNET[0]);
    let channels = [];
    await client.connect();
    try {
      let resp = await client.request({
        id: 1,
        command: "account_channels",
        account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
        destination_account: artist?.uid,
        ledger_index: "validated",
      });
  
      // Create a function to run on each API call.
      function printLedgerResult() {
        //console.log(resp["result"]);
      }
      const ledger_data_index = resp["result"]["ledger_index"];
      // Execute function at least once before checking for markers.
      do {
        //printLedgerResult();
  
        if (resp["result"]["marker"] === undefined) {
          break;
        }
  
        // Specify the same ledger and add the marker to continue querying.
        const ledger_marker = await client.request({
          command: "account_channels",
          account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
          destination_account: artist?.uid,
          ledger_index: ledger_data_index,
          marker: resp["result"]["marker"],
          ledger_index: "validated",
        });
        channels.push(...ledger_marker?.result?.channels);
        resp = ledger_marker;
      } while (true);
      client.disconnect();
      if (channels?.length > 0) {
        let c = channels.filter((c) => c?.channel_id === paymentChannel?.channel)[0];
        setFunds(c?.amount - c?.balance)
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  } */
  };

  const enableMonetization = () => {
    openModal();
    modalContent(
      <>
        <OptionModal
          heading={"Enabling Web Monetization"}
          additionalText={
            "Note: To enable web monetization, you will need to sign a transaction with a fee of 0.1 XRP to fund your account"
          }
        >
          <HoverOption
            onClick={() =>
              noCloseModal(
                <QRCodeForm
                  heading={"Enabling Web Monetization"}
                  body={{
                    memo: `${type[0].toUpperCase()}${type.substring(1)} (${profile?.uid } )is signing transaction to enable Web Monetization`,
                    instruction: `${type[0].toUpperCase()}${type.substring(1)} (${profile?.uid }) is signing transaction to enable Web Monetization`,
                    sender: profile?.uid,
                    [`${type}Id`]: id,
                    type: type,
                  }}
                  type={TRANSACTION_OPTIONS.ENABLE_WEB_MONETIZATION}
                  onSuccess={onMonetizationEnableSuccess}
                  onFail={onMonetizationEnableFail}
                  cancelQr={onMonetizationEnableCancel}
                />
              )
            }
          >
            Enable Web Monetization
          </HoverOption>
          <HoverOption>Cancel</HoverOption>
        </OptionModal>
      </>
    );
  };

  const onMonetizationEnableSuccess = (data) => {
    closeModal();
    modalContent();
    setPaymentChannel(data.paymentChannel);
    setFunds(data.funds);
  };

  const onMonetizationEnableFail = () => {
    closeModal();
    modalContent();
  };

  const onMonetizationEnableCancel = () => {
    closeModal();
    modalContent();
  };

  const claimFunds = () => {
    openModal();
    modalContent(
      <MicropaymentsFundsClaimer
        funds={funds}
        paymentChannel={paymentChannel}
        name={profile?.profile?.uid}
        id={id}
        type={`${type[0].toUpperCase()}${type.substring(1)}`}
        onSuccess={() => {
          closeModal();
          getProfile()
          modalContent();
        }}
      />
    );
  };

  const disableFunds = () => {
    openModal();
    modalContent(
      <MicropaymentFundsDisable
        funds={funds}
        paymentChannel={paymentChannel}
        name={profile?.profile?.uid}
        id={id}
        type={`${type[0].toUpperCase()}${type.substring(1)}`}
        onSuccess={() => {
          closeModal();
          setPaymentChannel()
          modalContent();
        }}
      />
    );
  };

  return (
    <>
      {isLoaded && paymentChannel?.channel && <ContentWrapper>
        <FormWrapper border>
          <FormH1 style={{whiteSpace: 'nowrap'}}>Micropayments Balance {/* <RiXrpLine size={30} style={{marginLeft: '2%'}} /> */}</FormH1>
          <FormWrapper border>{paymentChannel?.channel && funds / 1000000 > 0 && (
            <><SettingsWrapper style={{justifyContent: 'space-between'}}>
              <CurrencySelector setCurrency={setFundsCurrency} currency={fundsCurrency}/>
              <FormText>
                Available Funds:{" "}
                <div style={{ padding: "1%", whiteSpace: 'normal'}}>
                  {fundsCurrency !== "XRP" && !isRateLoaded && 'Loading Balance...'}
                  {fundsCurrency !== "XRP" && isRateLoaded && parseFloat((funds / 1000000) * curencyRate).toFixed(6)}
                  {fundsCurrency === "XRP" && parseFloat(funds / 1000000).toFixed(6)} {fundsCurrency}
                </div>
              </FormText>

              
            </SettingsWrapper>
            <SettingsWrapper> 
              <ActionButtonsContainer>
           <CustomButton style={{ margin: "0" }} onClick={() => {getFunds()}}><small>Refresh Funds <MdRefresh color={themeColors?.secondaryBg} /></small></CustomButton>
           
           {(funds / 1000000) >= 0.1 && (
             
               <ActionButton
               style={{ padding: "1% 2%" }}
                 onClick={() => {
                   claimFunds();
                 }}
               >
                 Claim Funds
               </ActionButton>
             )}
           <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => disableFunds()}
                >
                  Disable
                </ActionButton></ActionButtonsContainer>
            </SettingsWrapper></>
          )}</FormWrapper></FormWrapper></ContentWrapper>}
          {isLoaded && !paymentChannel?.channel && profile?.profile?.pendingFunds/1000000 > 0 && <FormWrapper>
            <FormH1>You have funds pending from your music being streamed, waiting to be claimed.</FormH1>
            <FormText>Enable Micropayments in to start earning funds in real-time whenever fans stream your music</FormText>
              
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    enableMonetization();
                  }}
                >
                  Enable
                </ActionButton>
            </FormWrapper>}
    </>
  )
}

export default Dashboard;
