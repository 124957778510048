import React, { useEffect, useState } from "react";
import {
  AlbumArtPreviewContainer,
  AlbumUploadsContainer,
  AlbumUploadsInput,
  ErrorWrapper,
  MusicTitleInfoContainer,
  MusicUploadAlbumArt,
  MusicUploadContentTitle,
  MusicUploadInfoText,
  MusicUploadTitleArtContainer,
  MusicUploadTitleTextWrapper,
  MusicUploadsButton,
  MusicUploadsButtonContainer,
  SongArtCoverWrapper,
  SongDetailsContainer,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
  SongDetailsWrapper,
  UploadProgressWrapper,
} from "../MusicUploadsFormElements";
import ImageUtils from "../../../utils/ImageUtils";
import { FormWrapper } from "../../CustomForm/CustomFormElements";
import PageIndexer from "../../PageIndexer";
import { Theme } from "../../WebsiteThemeElements";
import ScrollToTop from "../../ScrollToTop";
import Page1 from "./Page1";
import { PushSpinner } from "react-spinners-kit";
import FileUploader from "../../FileUploader";
import { AlbumArtPreview } from "../../AlbumArt";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import useMusic from "../../../hooks/useMusic";
import {
  containsObject,
  getObjectLength,
  getUniqueListBy,
  isrcReg,
} from "../../../utils";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import { convertRoyaltiesToArrObj } from "../../../utils/MusicUtils";
import {
  ALERT_TYPES,
  PODCASTER_SEARCH_TYPES,
  TRANSACTION_OPTIONS,
} from "../../../utils/Enum";
import { useAuth } from "../../../contexts/AuthContext";
import usePodcaster from "../../../hooks/usePodcaster";
import QRCodeForm from "../../QRCodeForm";
import { useModal } from "../../Modal/ModalContext";
import {UnavailableFeature } from "../../MenuOptions";
import OptionModal from "../../OptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useNavigate, useLocation } from "react-router-dom";
import { useAlert } from "../../Alert/AlertContext";
import { saveEpisodeAsDraft } from "../../../utils/dbUtils/draftsUtils";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const PodcastUpload = ({ info }) => {
  const {themeColors} = useWebsiteTheme()
  const { generateISRCCode, deleteDraft, uploadEpisode } = useMusic();
  const navigate = useNavigate();
  const location = useLocation();
  const { addAlert } = useAlert();
  const { currentUser } = useAuth();
  const { searchPodcasters } = usePodcaster({});
  const { defaultAlbumArt } = ImageUtils();
  const { closeModal, openModal, modalContent, noCloseModal } = useModal();

  const { goTo } = ScrollToTop();
  const [isLoaded, setLoaded] = useState(false);
  const [prevChanges, setPrevChanges] = useState({});
  const [thumbnail, setThumbnail] = useState(
    info?.thumbnail ? info?.thumbnail : {}
  );
  const [userPodcaster, setUserPodcaster] = useState(
    info?.userPodcaster ? info?.userPodcaster : {}
  );
  const [duration, setDuration] = useState();
  const [isrc, setISRC] = useState(info?.isrc ? info?.isrc : "");
  const [isIsrcValid, setIsrcValid] = useState(false);
  const [isrcType, setIsrcType] = useState(
    info?.isrcType ? info?.isrcType : "auto"
  );
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    info?.autoIsrcCode ? info?.autoIsrcCode : undefined
  );
  const [currency, setCurrency] = useState(
    info?.currency ? info?.currency : "WYM"
  );
  const [price, setPrice] = useState(info?.price ? info?.price : 0);
  const [genres, setGenres] = useState(info?.genres ? info?.genres : []);
  const [episodeName, setEpisodeName] = useState(
    info?.episodeName ? info?.episodeName : ""
  );
  const [episodeUpload, setEpisodeUpload] = useState(
    info?.episodeUpload ? info?.episodeUpload : {}
  );
  const [description, setDescription] = useState(
    info?.description ? info?.description : ""
  );
  const [releaseDate, setReleaseDate] = useState(
    info?.releaseDate ? info?.releaseDate : ""
  );
  const [podcasters, setPodcasters] = useState(
    info?.podcasters ? info?.podcasters : []
  );
  const [podcastersPayouts, setPodcastersPayouts] = useState(
    info?.podcastersPayouts ? info?.podcatsersPayouts : []
  );
  const [artistPayouts, setArtistPayouts] = useState(
    info?.artistPayouts ? info?.artistPayouts : []
  );

  const [episodeId, setEpisodeId] = useState(
    info?.episodeId ? info?.episodeId : undefined
  );
  const [errors, setErrors] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [isSubmitted, setSubmit] = useState(false);
  const [isSubscription, setSubscription] = useState(
    info?.isSubscription ? info?.isSubscription : false
  );
  const [isDownloadable, setDownloadable] = useState(
    info?.isDownloadable ? info?.isDownloadable : false
  );
  const [isExplicit, setExplicit] = useState(
    info?.isExplicit ? info?.isExplicit : false
  );
  const [isMintable, setMintable] = useState(
    info?.isMintable ? info?.isMintable : false
  );
  const [isStreamable, setStreamable] = useState(
    info?.isStreamable ? info?.isStreamable : true
  );
  const [isMusicContained, setMusicContained] = useState(
    info?.isMusicContained ? info?.isMusicContained : false
  );
  const [license, setLicense] = useState(info?.license ? info?.license : "ARR");
  const [timestamps, setTimestamps] = useState(
    info?.timestamps ? info?.timestamps : []
  );
  const [songs, setSongs] = useState(info?.songs ? info?.songs : []);
  const [hideButtons, setHideButtons] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setMainPodcaster();
    }
    if (!autoIsrcCode) {
      getISRCCode();
    }
    /*     const params = new URLSearchParams({id: 'test' });
      navigate( {pathname: location.pathname, search: params.toString()} )  
   */
    return () => {};
  }, [!isLoaded]);

  useEffect(() => {
    if (getObjectLength(episodeUpload) > 0 || episodeId) {
      autoSave();
    }
    return () => {};
  }, []);

  const autoSave = () => {
    /* const interval = setTimeout(() => {
     if(window.location.pathname === location.pathname){ 
      saveEpisodeAsDraft({
        userPodcaster: userPodcaster,
        isrcType: isrcType,
        isrc: isrc,
        autoIsrcCode: autoIsrcCode,
        thumbnail: thumbnail,
        songs: songs,
        podcasters: podcasters,
        timestamps: timestamps,
        description: description,
        isMusicContained: isMusicContained,
        isStreamable: isStreamable,
        isMintable: isMintable,
        isExplicit: isExplicit,
        isDownloadable: isDownloadable,
        isSubscription: isSubscription,
        episodeId: episodeId,
        artistPayouts: artistPayouts,
        podcastersPayouts: podcastersPayouts,
        releaseDate: releaseDate,
        episodeUpload: episodeUpload,
        episodeName: episodeName,
        genres: genres,
        price: price,
        currency: currency,
        uploadedBy: currentUser?.uid
      }).then((data) => {
        //console.log(data)
        if (data && !data?.error) {
          setEpisodeId(data.message);
          const url = new URL(window.location.href);
                const params = new URLSearchParams(url.search);
                params.set("id", data?.message)
                if(window.location.pathname === location.pathname){
                navigate(`${location.pathname}?${params.toString()}`,{
                  replace:true},
                );}
          return
        }
      
      })
      .catch((err) => {
        //console.log(err)
      })}
    }, 25000);
    return () => clearInterval(interval); */
  };

  const setMainPodcaster = async () => {
    await searchPodcasters(PODCASTER_SEARCH_TYPES.ADDRESS, currentUser?.uid)
      .then((podcaster) => {
        if (podcasters?.length === 0) {
          setUserPodcaster(podcaster);
          setPodcasters([podcaster]);
        } else if (
          podcasters?.length > 0 &&
          containsObject(podcasters, podcaster)
        ) {
          setPodcasters((prev) => {
            return getUniqueListBy([podcaster, ...prev]);
          });
          setUserPodcaster(podcaster);
        }
        if (podcastersPayouts?.length === 0) {
          setPodcastersPayouts([{ ...podcaster, percentage: 100 }]);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setCreativeCommons = (id) => {
    setLicense(id);
  };

  const setPageNum = (num) => {
    setPageNumber(num);
  };

  const prevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const cancelMusicUpload = () => {
    if (
      episodeId?.length > 0 ||
      episodeName?.length > 0 ||
      getObjectLength(thumbnail) > 0 ||
      getObjectLength(episodeUpload) > 0 ||
      songs?.some((s, index) => getObjectLength(s?.songUpload) > 0)
    ) {
      openModal();

      modalContent(
        <>
          <OptionModal heading={"Are you sure you want to leave uploading"}>
            <HoverOption
              onClick={() => {
                navigate(-1);
                closeModal();
                modalContent();
              }}
            >
              Leave
            </HoverOption>
            <HoverOption
              onClick={() => {
                closeModal();
                modalContent();
              }}
            >
              Cancel
            </HoverOption>
            {episodeId?.length > 0 && (
              <HoverOption
                onClick={() => {
                  closeModal();
                  modalContent();
                  navigate(-1);
                  deleteDraft(episodeId);
                }}
              >
                Leave & Delete Draft
              </HoverOption>
            )}
          </OptionModal>
        </>
      );
      return;
    }
    navigate(-1);
    goTo("/home");
  };

  const validateEpisodeInfo = () => {
    setErrors();
    let e = {};
    if (episodeName?.trim()?.length === 0) {
      e = {
        ...e,
        episodeName: "An episode cannot be released without a name",
      };
    }
    if (!thumbnail?.url || !thumbnail) {
      e = {
        ...e,
        thumbnail: "Upload a thumbnail before releasing",
      };
    }
    if (genres?.length === 0) {
      e = {
        ...e,
        genres: "Select one or more genre/topic",
      };
    }
    if (!episodeUpload || !episodeUpload?.url) {
      e = { ...e, episodeUpload: "Upload Episode before releasing" };
    }
    if (isMusicContained && songs?.length === 0) {
      e = {
        ...e,
        songs: "Select songs included in episode",
      };
    }
    if (
      isrcType === "custom" &&
      (!isrcReg.test(isrc?.trim()) || isrc?.trim().length === 0)
    ) {
      e = {
        ...e,
        isrc: "invalid ISRC code",
      };
    }
    if (
      podcastersPayouts?.length === 0 ||
      podcastersPayouts?.some((p) => p?.percentage === 0)
    ) {
      e = {
        ...e,
        payouts: "Please enter payout percentage for podcasters",
      };
    }
    //console.log(e)
    if (getObjectLength(e) > 0) {
      setErrors(e);
      return false;
    } else {
      return true;
    }
  };

  const cancelUpload = () => {
    addAlert({
      title: "Episode Upload",
      message: "Upload Cancelled",
      type: ALERT_TYPES.WARNING,
    });
    setSubmit(false);
    setHideButtons(false);
    closeModal();
    modalContent();
  };

  const onSuccess = (id) => {
    //console.log(id)
    modalContent();
    closeModal();
    setSubmit(true);
    setHideButtons(false);
    setEpisodeId(id);
    setErrors({ message: "Episode Successfully Released" });
  };

  const onFailure = () => {
    modalContent();
    closeModal();
    setSubmit(false);
    setHideButtons(false);
    setErrors({
      message: "Your submission was not successful, please try again",
    });
  };

  const uploadData = (type) => {
    setHideButtons(true);
    openModal();
    noCloseModal(
      <ModalLoadingScreen text={"Validating Upload. Please Wait..."} />
    );
    try {
      if (validateEpisodeInfo()) {
        openModal();
        noCloseModal(
          <QRCodeForm
            type={TRANSACTION_OPTIONS.MUSIC_UPLOAD.EPISODE}
            heading={`Release of ${episodeName} by ${podcasters[0].podcasterName}`}
            body={{
              sender: currentUser?.uid,
              memo: `${currentUser?.uid} is submitting the episode ${episodeName} to be released`,
              instruction: `${currentUser?.uid} is submitting the episode ${episodeName} to be released`,
              price: price,
              isrc:
                isrcType === "custom"
                  ? isrc?.trim()?.length > 0
                    ? isrc?.trim()
                    : autoIsrcCode
                  : autoIsrcCode,
              episodeName: episodeName,
              episodeId: episodeId,
              genres: genres,
              podcasters: podcasters,
              thumbnailRef: thumbnail?.ref,
              thumbnail: thumbnail?.url,
              episodeRef: episodeUpload?.ref,
              url: episodeUpload?.url,
              duration: episodeUpload?.duration
                ? episodeUpload?.duration
                : duration,
              isDownloadable,
              isStreamable,
              isSubscription,
              isMusicContained,
              expicit: isExplicit,
              isMintable: isMintable,
              currency: isDownloadable
                ? currency
                  ? currency
                  : "WYM"
                : undefined,
              about: description,
              uploadedBy: currentUser.uid,
              releaseDate: releaseDate ? releaseDate : undefined,
              displayDate: releaseDate ? new Date(releaseDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
              type: "publish",
              timestamps: timestamps?.length > 0 ? timestamps : undefined,
              songs: isMusicContained ? songs : undefined,
              licensing: license,
              royalties: podcastersPayouts,
              artistRoyalties: isMusicContained ? artistPayouts : undefined,
            }}
            onFail={onFailure}
            onSuccess={onSuccess}
            cancelQr={cancelUpload}
          />
        );
        return;
      } else {
        closeModal();
        modalContent();
        setErrors((prev) => {
          return {
            ...prev,
            message: "Unable to upload, please review episode information",
          };
        });
      }
    } catch (err) {
      console.error(err);
      closeModal();
      modalContent();
      setHideButtons(false);
      addAlert({
        title: "Draft Error",
        message: "Failed to save to drafts",
        type: ALERT_TYPES.DANGER,
      });
    }
  };

  const saveAsDraft = () => {
    setHideButtons(true);
    addAlert({
      title: "Saving Draft",
      message: "Saving episode to drafts...",
      type: ALERT_TYPES.INFO,
    });
    try {
      saveEpisodeAsDraft({
        userPodcaster: userPodcaster,
        isrcType: isrcType,
        isrc: isrc,
        autoIsrcCode: autoIsrcCode,
        thumbnail: thumbnail,
        songs: songs,
        podcasters: podcasters,
        timestamps: timestamps,
        description: description,
        isMusicContained: isMusicContained,
        isStreamable: isStreamable,
        isMintable: isMintable,
        isExplicit: isExplicit,
        isDownloadable: isDownloadable,
        isSubscription: isSubscription,
        episodeId: episodeId,
        artistPayouts: artistPayouts,
        podcastersPayouts: podcastersPayouts,
        releaseDate: releaseDate,
        episodeUpload: episodeUpload,
        episodeName: episodeName,
        license: license,
        genres: genres,
        price: price,
        currency: currency,
        uploadedBy: currentUser?.uid,
      })
        .then((data) => {
          //console.log(data)
          setHideButtons(false);
          if (data && !data?.error) {
            // console.log(data)
            setEpisodeId(data?.message);
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            params.set("id", data?.message);
            navigate(`${location.pathname}?${params.toString()}`, {
              replace: true,
            });
            addAlert({
              title: "Draft Saved!",
              message: "Episode saved to drafts",
              type: ALERT_TYPES.SUCCESS,
            });
            setErrors({ message: "Draft saved!" });
            return;
          }
          addAlert({
            title: "Draft Error",
            message: "Failed to save to drafts",
            type: ALERT_TYPES.DANGER,
          });
        })
        .catch((err) => {
          //console.log(err)
          setHideButtons(false);
          addAlert({
            title: "Draft Error",
            message: "Failed to save to drafts",
            type: ALERT_TYPES.DANGER,
          });
        });
    } catch (err) {
      console.error(err);
      setHideButtons(false);
      addAlert({
        title: "Draft Error",
        message: "Failed to save to drafts",
        type: ALERT_TYPES.DANGER,
      });
    }
  };

  const getISRCCode = async () => {
    return await generateISRCCode()
      .then((code) => {
        if (!code?.error || code !== false) {
          setAutoIsrcCode(code);
          setISRC(code);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addTimestamp = (label, time) => {
    let t = timestamps;
    let index = t?.findIndex((timestamp) => timestamp?.time === time);
    if (index >= 0) {
      t[index] = { label: label, time: time };
    } else {
      t.push({ label: label, time: time });
    }
    setTimestamps(
      t?.sort((a, b) => (a.time > b.time ? 1 : b.time > a.time ? -1 : 0))
    );
  };

  const deleteTimestamp = (i) => {
    let t = timestamps.filter((timestamp, index) => i !== index);
    setTimestamps(t);
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case "title":
        setEpisodeName(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      case "releaseDate":
        setReleaseDate(e.target.value);
        break;
      case "isrc":
        setISRC(e.target.value.toUpperCase());
        if (isrcReg.test(e.target.value)) {
          setIsrcValid(true);
        } else {
          setIsrcValid(false);
        }
        break;

      case "isrcType":
        switch (e.target.value) {
          case "auto":
            setIsrcType("auto");
            setISRC(autoIsrcCode);
            break;

          case "custom":
            setISRC("");
            setIsrcType("custom");

            break;

          default:
            setIsrcType("auto");
            setISRC(autoIsrcCode);
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleToggle = (value) => {
    switch (value) {
      case "explicit":
        setExplicit(!isExplicit);
        break;
      case "streamable":
        /* setStreamable(!isStreamable); */
        setSubscription(false);
        break;
      case "downloadable":
        /* setDownloadable(!isDownloadable); */
        openModal();
        modalContent(<UnavailableFeature />);
        break;
      case "subscription":
        /* setSubscription(!isSubscription);
        setStreamable(false); */
        openModal();
        modalContent(<UnavailableFeature />);
        break;
      case "mintable":
        openModal();
        modalContent(<UnavailableFeature />);
        break;
      case "music":
        setMusicContained(!isMusicContained);
        break;

      default:
        break;
    }
  };

  const addPodcaster = (podcaster) => {
    setPodcasters((prev) => {
      return [...prev, podcaster];
    });
  };

  const removePodcaster = (index) => {
    let p = podcasters;
    setPodcasters(p.filter((podcaster, i) => i !== index));
  };

  const addGenre = (genre) => {
    let g = genres;
    g.push(genre);
    setGenres(g);
  };

  const removeGenre = (index) => {
    let g = genres;
    setGenres(g.filter((genre, i) => i !== index));
  };

  const addSong = async (song) => {
    let s = songs;
    let songRoyalties = convertRoyaltiesToArrObj(song?.royalties);
    let artistRoyalties = [];
    let aPayouts = artistPayouts;
    let podPayouts = podcastersPayouts;
    for (let i = 0; i < songRoyalties?.length; i++) {
      let royalty = parseFloat(
        1 * (songRoyalties[i]?.percentage / 100)
      ).toFixed(2);
      artistRoyalties.push({
        artistId: songRoyalties[i]?.artistId,
        percentage: royalty,
      });
      let index = artistPayouts?.findIndex(
        (a) => a?.artistId === songRoyalties[i]?.artistId
      );
      if (index > -1) {
        aPayouts = aPayouts.map((a, i) => {
          if (index === i) {
            return {
              artistId: a?.artistId,
              percentage: parseFloat(royalty + a?.percentage),
            };
          }
          return a;
        });
      } else {
        aPayouts.push({
          artistId: songRoyalties[i]?.artistId,
          percentage: royalty,
        });
      }
    }
    podPayouts = podPayouts.map((p) => {
      if (p?.podcasterId === userPodcaster?.podcasterId) {
        return { ...p, percentage: parseFloat(p?.percentage - 1).toFixed(0) };
      } else {
        return p;
      }
    });
    setArtistPayouts(aPayouts);
    setPodcastersPayouts(podPayouts);
    s.push({ ...song, royalties: artistRoyalties });
    setSongs(s);
    return
  };
  
  const addPlaylistSongs = async (plSongs) => { 
    let s = songs;
    let podPayouts = podcastersPayouts;
    for(let i = 0; i < plSongs?.length; i++){
      let song = plSongs[i]
    let songRoyalties = convertRoyaltiesToArrObj(song?.royalties);
    let artistRoyalties = [];
    let aPayouts = artistPayouts;
    
    for (let i = 0; i < songRoyalties?.length; i++) {
      let royalty = parseFloat(
        1 * (songRoyalties[i]?.percentage / 100)
      ).toFixed(2);
      artistRoyalties.push({
        artistId: songRoyalties[i]?.artistId,
        percentage: royalty,
      });
      let index = artistPayouts?.findIndex(
        (a) => a?.artistId === songRoyalties[i]?.artistId
      );
      if (index > -1) {
        aPayouts = aPayouts.map((a, i) => {
          if (index === i) {
            return {
              artistId: a?.artistId,
              percentage: parseFloat(royalty + a?.percentage),
            };
          }
          return a;
        });
      } else {
        aPayouts.push({
          artistId: songRoyalties[i]?.artistId,
          percentage: royalty,
        });
      }
    }
    setArtistPayouts(aPayouts);
    s.push({ ...song, royalties: artistRoyalties });
    setSongs(s);
  }
    podPayouts = podPayouts.map((p) => {
      if (p?.podcasterId === userPodcaster?.podcasterId) {
        return { ...p, percentage: parseFloat(p?.percentage - plSongs?.length).toFixed(0) };
      } else {
        return p;
      }
    });
    setPodcastersPayouts(podPayouts);
    return
  };

  const removeSong = (index) => {
    let s = songs;
    let podPayouts = podcastersPayouts;
    let aPayouts = artistPayouts;
    setSongs(s.filter((song, i) => i !== index));
    for (let i = 0; i < s[index]?.royalties?.length; i++) {
      let royalty = s[index]?.royalties[i].percentage;
      let artistIndex = aPayouts.indexOf(
        (a) => a?.artistId === s[index]?.royalties[i]?.artistId
      );
      if (artistIndex > -1) {
        if (aPayouts[artistIndex].percentage - royalty > 0) {
          aPayouts[artistIndex] = {
            artistId: aPayouts[artistIndex],
            percentage: aPayouts[artistIndex].percentage - royalty,
          };
        } else {
          aPayouts = aPayouts.filter((artist, i) => i !== artistIndex);
        }
      }
    }
    podPayouts = podPayouts.map((p) => {
      if (p?.podcasterId === userPodcaster?.podcasterId) {
        return { ...p, percentage: parseFloat(parseInt(p?.percentage) + 1).toFixed(0) };
      } else {
        return p;
      }
    });
    setArtistPayouts(aPayouts);
    setPodcastersPayouts(podPayouts);
  };

  const addPodcasterRoyalty = (podcaster) => {
    let p = podcastersPayouts;
    let index = p.findIndex(
      (pod) => podcaster?.podcasterId === pod?.podcasterId
    );
    if (index > -1) {
      p = p.map((pod) => {
        if (pod?.podcasterId === podcaster?.podcasterId) {
          return podcaster;
        } else {
          return pod;
        }
      });
    } else {
      p.push(podcaster);
    }
    setPodcastersPayouts(p);
  };

  const removePodcasterRoyalty = (index) => {
    let p = podcastersPayouts;
    setPodcastersPayouts(p.filter((podcaster, i) => i !== index));
  };

  return (
    <>
      <MusicTitleInfoContainer>
        <>
          <MusicUploadTitleArtContainer>
            <MusicUploadAlbumArt
              src={thumbnail?.url ? thumbnail.url : defaultAlbumArt}
              alt={episodeName}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />
          </MusicUploadTitleArtContainer>
          <MusicUploadTitleTextWrapper>
            <MusicUploadContentTitle style={{ fontStyle: "italic" }}>
              {episodeName.length > 0 ? episodeName : "Episode Title"}{" "}
            </MusicUploadContentTitle>
            <>
              <MusicUploadInfoText>
                <>
                  {podcasters?.length > 0 &&
                    podcasters.map((podcaster, index) => {
                      if (index === podcasters.length - 2) {
                        return podcaster.podcasterName + " & ";
                      } else if (index === podcasters.length - 1) {
                        return podcaster.podcasterName;
                      } else if (index <= podcasters.length - 1) {
                        return podcaster.podcasterName + ", ";
                      }
                    })}
                </>
              </MusicUploadInfoText>
            </>
            <MusicUploadInfoText>
              {releaseDate
                ? new Date(releaseDate).toDateString()
                : "Release Date"}
            </MusicUploadInfoText>
          </MusicUploadTitleTextWrapper>
        </>
        {
          <SongDetailsSection>
            <AlbumUploadsContainer
              for={
                episodeUpload?.progress &&
                episodeUpload.progress > 0 &&
                !episodeUpload?.url
                  ? ""
                  : "episodeUpload"
              }
            >
              {episodeUpload?.url && episodeUpload?.progress === 100 && (
                <span style={{ display: "flex", flexDirection: "column" }}>
                  <SongDetailsText>Replace Audio File</SongDetailsText>
                  <SongDetailsText style={{ fontStyle: "italic" }}>
                    Upload Complete
                  </SongDetailsText>
                </span>
              )}
              {!episodeUpload?.url && !episodeUpload?.progress && (
                <>
                  <SongDetailsText>Upload Episode</SongDetailsText>
                  <SongDetailsText style={{ fontStyle: "italic" }}>
                    Select lossless format .wav, .mp3 or .ogg
                  </SongDetailsText>
                </>
              )}
              {episodeUpload.progress > 0 && episodeUpload.progress < 100 && (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SongDetailsText>Uploading...</SongDetailsText>
                  <SongDetailsText
                    style={{ padding: "2%", textDecoration: "underline" }}
                    onClick={() => {}}
                  >
                    Cancel
                  </SongDetailsText>
                </span>
              )}

              <FileUploader
                id={"episodeUpload"}
                name={"episodeUpload"}
                fileTypes={".wav,.mp3,.ogg"}
                userType={"podcasters"}
                setDuration={setDuration}
                fileUpload={episodeUpload}
                setFileUpload={setEpisodeUpload}
                acceptMultiple={false}
              />
            </AlbumUploadsContainer>
            {episodeUpload?.progress > 0 && !episodeUpload?.url && (
              <>
                <UploadProgressWrapper>
                  <PushSpinner loading={episodeUpload?.url ? false : true} />

                  <SongDetailsText
                    style={{ fontStyle: "italic", padding: "2%" }}
                  >
                    {episodeUpload?.progress > 0 &&
                      episodeUpload?.progress < 100 &&
                      !episodeUpload?.url &&
                      `${episodeUpload?.progress.toFixed(2)}%`}
                    {episodeUpload?.progress === 100 &&
                      !episodeUpload?.url &&
                      `Processing...`}
                  </SongDetailsText>
                </UploadProgressWrapper>
              </>
            )}
            <MusicUploadInfoText>
              {episodeUpload?.url ? episodeUpload?.file?.name ? episodeUpload?.file?.name : "" : ""}
            </MusicUploadInfoText>
          </SongDetailsSection>
        }
      </MusicTitleInfoContainer>
      <SongDetailsContainer>
        <SongDetailsWrapper>
          {pageNumber === 1 && (
            <FormWrapper>
              <Page1
                releaseDate={releaseDate}
                episodeDescription={description}
                genres={genres}
                addGenre={addGenre}
                removeGenre={removeGenre}
                isrc={isrc}
                errors={errors}
                isrcType={isrcType}
                addPodcaster={addPodcaster}
                removePodcaster={removePodcaster}
                podcasters={podcasters}
                currency={currency}
                setCurrency={setCurrency}
                isMusicContained={isMusicContained}
                handleToggle={handleToggle}
                handleInput={handleInput}
                episodeName={episodeName}
                isDownloadable={isDownloadable}
                isExplicit={isExplicit}
                isStreamable={isStreamable}
                isSubscription={isSubscription}
              />
            </FormWrapper>
          )}
          {episodeUpload?.url && (
            <>
              {pageNumber === 2 && (
                <FormWrapper>
                  <Page2
                    license={license}
                    setCreativeCommons={setCreativeCommons}
                  />
                </FormWrapper>
              )}
              {pageNumber === 3 && (
                <FormWrapper>
                  <Page3
                    timestamps={timestamps}
                    setTimestamps={addTimestamp}
                    deleteTimestamp={deleteTimestamp}
                    duration={episodeUpload?.duration}
                  />
                </FormWrapper>
              )}
              {pageNumber === 4 && (
                <FormWrapper>
                  <Page4
                    addPodcaster={addPodcasterRoyalty}
                    isMusicContained={isMusicContained}
                    payoutArtists={artistPayouts}
                    errors={errors}
                    podcasters={podcasters}
                    removePodcaster={removePodcasterRoyalty}
                    podcastersPayouts={podcastersPayouts}
                    addSong={addSong}
                    addPlaylistSongs={addPlaylistSongs}
                    removeSong={removeSong}
                    songs={songs}
                  />
                </FormWrapper>
              )}
            </>
          )}
        </SongDetailsWrapper>
        {episodeUpload?.url && (
          <SongArtCoverWrapper>
            <>
              <SongDetailsSectionTitle>Thumbnail</SongDetailsSectionTitle>

              <AlbumUploadsContainer
                for={
                  thumbnail?.progress > 0 && !thumbnail?.url ? "" : "thumbnail"
                }
              >
                <span style={{ display: "flex" }}>
                  <SongDetailsText>
                    {thumbnail?.url ? (
                      `${thumbnail?.file?.name}`
                    ) : (
                      <small style={{ fontStyle: "italic" }}>
                        Click here to add thumbnail (Size should be at least
                        1600 x 1600 pixels){" "}
                      </small>
                    )}
                  </SongDetailsText>
                  {thumbnail?.progress > 0 && !thumbnail?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Cancel
                    </SongDetailsText>
                  )}
                  {thumbnail?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                </span>
                <AlbumArtPreviewContainer thumbnail={thumbnail?.url}>
                  {thumbnail?.progress > 0 && !thumbnail?.url && (
                    <>
                      <LoadingScreen
                        transparent
                        isLoaded={thumbnail.url ? true : false}
                      />
                      {thumbnail.progress < 100
                        ? `${thumbnail?.progress.toFixed(2)}%`
                        : `Processing`}
                    </>
                  )}
                  {(thumbnail?.url || !thumbnail?.progress) && (
                    <>
                      <AlbumArtPreview
                        src={thumbnail?.url ? thumbnail.url : defaultAlbumArt}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </>
                  )}
                </AlbumArtPreviewContainer>
                <FileUploader
                  id="thumbnail"
                  type={"file"}
                  name="thumbnail"
                  fileTypes={"image/x-png,image/gif,image/jpeg, image/jpg"}
                  acceptMultiple="false"
                  fileUpload={thumbnail}
                  setFileUpload={setThumbnail}
                  userType={"podcaster"}
                />
              </AlbumUploadsContainer>
            </>
          </SongArtCoverWrapper>
        )}
      </SongDetailsContainer>
      {errors?.message && (
        <ErrorWrapper
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <SongDetailsText error>{errors?.message}</SongDetailsText>
        </ErrorWrapper>
      )}
      {episodeUpload?.url && (
        <PageIndexer pages={4} currentPage={pageNumber} onClick={setPageNum} />
      )}
      <MusicUploadsButtonContainer>
        <MusicUploadsButton
          outline={!hideButtons}
          to={"#"}
          onClick={() => saveAsDraft()}
        >
          Save Draft
        </MusicUploadsButton>

        {episodeUpload?.url && (
          <>
            {" "}
            {pageNumber > 1 && !isSubmitted && (
              <MusicUploadsButton outline onClick={() => prevPage()}>
                Prev
              </MusicUploadsButton>
            )}
            {pageNumber < 4 && pageNumber > 1 && (
              <MusicUploadsButton
                outline
                onClick={() => {
                  nextPage();
                  return;
                }}
              >
                Next
              </MusicUploadsButton>
            )}
            {pageNumber === 4 && (
              <MusicUploadsButton
                outline={!hideButtons}
                onClick={() => {
                  //console.log(episodeId)
                  if (isSubmitted === true) {
                    goTo(`/episode/${episodeId}`);
                    return;
                  } else {
                    if (!hideButtons) {
                      uploadData("publish");
                    }
                    return;
                  }
                }}
              >
                {isSubmitted === true ? "View Release" : "Release Episode"}
              </MusicUploadsButton>
            )}
          </>
        )}
        {!isSubmitted && (
          <MusicUploadsButton
            outline
            backgroundColor={themeColors.accentColor}
            onClick={() => {
              cancelMusicUpload();
            }}
          >
            Cancel
          </MusicUploadsButton>
        )}
      </MusicUploadsButtonContainer>
    </>
  );
};

export default PodcastUpload;
